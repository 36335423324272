import React from 'react';
import Tiles from './tiles/tiles';
import Tow from './tow/tow';

const MyCarousel = () => {

  return (
    <div>
      <Tiles/>
      <Tow/>
    </div>
  );
}

export default MyCarousel;