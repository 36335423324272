import React from 'react';
import Index from '../footer';
import Baner from './hello/banner';
import Parts from './spare/about';


const Spare = () => {
 return (
    <div>
      <Baner />
      <Parts/>
      
      <Index />
    </div>
 );
};

export default Spare;